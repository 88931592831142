import React from 'react';

function Page10() {
  return (
    <>
      <h1>Prosedur Pembayaran</h1>

      <ul>
          <li>Mitra Transporter diharapkan untuk mengirim invoice dalam bentuk softcopy ke email <strong>paymentteam@kargo.tech</strong></li>
          <hr/>
          <li>Proses pembayaran invoice bagi DO yang tidak memiliki klaim akan membutuhkan waktu 0-7 hari kerja setelah invoice diterima pihak Kargo.</li>
          <hr/>
          <li>Proses pembayaran invoice bagi DO yang memiliki klaim akan membutuhkan waktu lebih lama. Proses pelunasan bagi DO yang memiliki klaim dengan estimasi 1-30 hari kerja dari Invoice diterima karena menunggu kepastian dari pihak shipper.</li>
          <hr/>
          <li>Mitra Transporter yang terdaftar sebagai <strong>Pengusaha Kena Pajak (PKP) dan pembayaran Kargo dengan down payment 50% dan pelunasan 50%, dihimbau untuk mengeluarkan Invoice dan Faktur Pajak pada pembayaran pelunasan.</strong></li>
          <hr/>
          <li>Mitra Transporter dihimbau juga untuk memisahkan Invoice untuk pembayaran antara plat kuning dan plat hitam (jika ada), demi kemudahan proses pembayaran dan perpajakan yang menyangkut di dalamnya.</li>
      </ul>
    </>
  );
}

export default Page10;

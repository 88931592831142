import React, { useState, useEffect } from 'react';

import {
  Button, AppBar, Toolbar,
  IconButton, Typography, LinearProgress,
  ThemeProvider
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { createMuiTheme } from '@material-ui/core/styles';

import Page1 from './Page1';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import Page7 from './Page7';
import Page8 from './Page8';
import Page9 from './Page9';
import Page10 from './Page10';
import Page11 from './Page11';

import './style.css';

const pageTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#5252ff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    }
  }
});

const appbarTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff'
    }
  }
});

const pages = [
  Page1, Page3,
  Page4, Page5, Page6, 
  Page7, Page8, Page9, 
  Page10, Page11
];

function App() {
  
  const [page, setPage] = useState(0);
  const [isAccepted, setAccepted] = useState(false);

  useEffect(() => {
    window.KargoNavigation.disableLoading();
  });

  function incrementPage() {
    if (page === pages.length - 1) {
      window.KargoNavigation.finishReading();
    } else {
      window.scrollTo(0, 0);
      setPage(page + 1);
    }
  }

  function decrementPage() {
    if (page === 0) {
      window.KargoNavigation.navigateUp();
    } else {
      window.scrollTo(0, 0);
      setPage(page - 1);
    }
  }

  const PageComponent = pages[page];
  const renderedPageComponent = (page < pages.length - 1) ? <PageComponent /> : <Page11 isChecked={isAccepted} onCheckedChange={e => setAccepted(e.target.checked)} />;
  
  const progress = (page + 1) / pages.length * 100;
  const isButtonDisabled = page === pages.length - 1 && !isAccepted;

  return (
    <ThemeProvider theme={pageTheme}>
      
      <div style={{ top: 0, left: 0, position: 'fixed', width: '100vw' }}>
        <ThemeProvider theme={appbarTheme}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={decrementPage}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">
                Syarat &amp; Ketentuan
              </Typography>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <LinearProgress variant="determinate" value={progress} />
      </div>
      
      <div style={{ marginTop: '76px' }}>
        
        <div className="tnc-text">
          {renderedPageComponent}
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" style={{ width: '86.67%', marginBottom: '16px' }} disabled={isButtonDisabled} onClick={incrementPage}>
            {(page < 10) ? 'Berikutnya' : 'Lanjut'}
          </Button>
        </div>

      </div>

    </ThemeProvider>
  );
}

export default App;

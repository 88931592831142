import React from 'react';

function Page7() {
  return (
    <>
      <h1>Alamat Pengembalian Surat Jalan dan Pengiriman Invoice</h1>
      <p>Surat Jalan dan Invoice dapat dikirimkan ke alamat dibawah ini:</p>
      <ul>
          <li>Pitstop Cibitung - pengiriman Jawa Barat dan sekitarnya<br/>
              Kawasan Industri MM2100, Jl. Kalimantan, Gandamekar, Kec. Cikarang Bar., Bekasi, Jawa Barat 17530<br/>
              <a href="https://goo.gl/maps/t7GEeoexkfdTWGMi7">Lihat di Google Maps</a></li>
          <hr/>
          <li>Pitstop Bawen - pengiriman Jawa Tengah dan sekitarnya<br/>
              Parkir Umum Kargo, Jl. Raya Semarang - Bawen Km 32, Jl. Soekarno - Hatta No.Kel, Harjosari, Kec. Ambarawa, Semarang, Jawa Tengah 50661<br/>
              <a href="https://goo.gl/maps/JqzYzvygssaPo8wV9">Lihat di Google Maps</a></li>
          <hr/>
          <li>Pitstop Tangerang - pengiriman daerah Tangerang dan sekitarnya<br/>
              Jl. MH. Thamrin, RT.002/RW.001, Cikokol, Kec. Tangerang, Kota Tangerang, Banten 15117<br/>
              <a href="https://goo.gl/maps/UTgfhB2yFVgyQtgr9">Lihat di Google Maps</a></li>
          <hr/>
          <li>Pitstop Pasuruan - pengiriman daerah Pasuruan dan sekitarnya<br/>
              Parkiran Umum Kargo, Jl. Raya Surabaya - Malang Desa No.Km. 48, Petung Sari, Tawang Rejo, Kec. Pandaan, Pasuruan, Jawa Timur 67156<br/>
              <a href="https://goo.gl/maps/eCUx8nP8MAu9sZ3KA">Lihat di Google Maps</a></li>
      </ul>
    </>
  );
}

export default Page7;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (!window.KargoNavigation) {
  window.KargoNavigation = {
    finishReading: () => { console.log("finishReading stub!"); },
    navigateUp: () => { console.log("navigateUp stub!"); },
    disableLoading: () => { console.log("disableLoading stub!"); }
  };

} else {
  // some method might not be available
  const kargoNavigation = window.KargoNavigation;
  
  if (!kargoNavigation.finishReading) {
    kargoNavigation.finishReading = () => { console.log("finishReading stub!"); };
  }

  if (!kargoNavigation.navigateUp) {
    kargoNavigation.navigateUp = () => { console.log("navigateUp stub!"); };
  }

  if (!kargoNavigation.disableLoading) {
    kargoNavigation.disableLoading = () => { console.log("disableLoading stub!"); };
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

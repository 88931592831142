import React from 'react';

function Page3() {
  return (
    <>
      <h1>Asuransi Muatan</h1>
      
      <ul>
        <li>Harga yang tertera akan dipotong asuransi muatan dengan premi <strong>Rp 40,000,- (Empat Puluh Ribu Rupiah)</strong> yang hanya akan menanggung biaya klaim sampai dengan sebesar <strong>Rp 20,000,000,- (Dua Puluh Juta Rupiah) untuk setiap order.</strong></li>
        <hr/>
        <li>Asuransi akan menanggung 95% dari total klaim <strong>sampai dengan Rp 20,000,000,-</strong> dan 5% dari nilai klaim (min. Rp 25,000, max Rp. 1,000,000) ditanggung oleh Mitra Transporter.</li>
        <hr/>
        <li>Untuk nilai klaim dibawah Rp 65,000 tidak akan dikenakan pemotongan 5% (min Rp 25,000 dan max Rp 1,000,000) dengan catatan Surat Jalan kembali tepat waktu (Sesuai dengan ketentuan Surat Jalan).
          <div className="boxed">Asuransi tidak menanggung klaim karena barang kurang/hilang tanpa bukti (contoh berita acara dari pihak kepolisian) atau lebih barang yang di charge oleh shipper. Maka dari itu, diharapkan dari mitra untuk mempersiapkan bukti lengkap (contoh foto/video/tanda terima retur) agar dilampirkan ke pihak shipper.</div>
        </li>
        <hr/>
        <li>Segala keputusan terkait asuransi merupakan kewenangan perusahaan asuransi dan Kargo tidak dapat dimintakan pertanggungjawaban.</li>
      </ul>
    </>
  );
}

export default Page3;

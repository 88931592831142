import React from 'react';

function Page5() {
  return (
    <>
      <h1>Ketentuan Claim</h1>
      
      <ul>
        <li>Dokumentasikan (foto / video) situasi unit setelah muat &amp; bongkar, susunan produk, segel unit, dll) agar apabila terjadi klaim, dokumentasi tersebut dapat diajukan untuk dipertimbangkan kembali kepada pihak Shipper.</li>
        <hr/>
        <li>Mitra Transporter yang <strong>tidak mengikuti program Asuransi Muatan akan bertanggung jawab atas biaya klaim secara penuh.</strong></li>
        <hr/>
        <li>Pemotongan biaya klaim dan 5% dari asuransi akan dilakukan di saat proses <strong>pelunasan.</strong></li>
        <hr/>
        <li>Berita Acara Klaim yang sudah ditandatangani oleh mitra pengemudi akan dianggap final, maka harap dicek dengan seksama jumlah dan catatan yang tertera di Berita Acara Klaim.</li>
        <hr/>
        <li>Keputusan terakhir klaim tetap ada di pihak Shipper, sehingga nominal klaim perlu menunggu dari pihak Shipper terlebih dahulu.</li>
        <hr/>
        <li>Untuk nilai klaim dibawah Rp 65,000 tidak akan dikenakan pemotongan 5% (min Rp 25,000 dan max Rp 1,000,000) dengan catatan Surat Jalan kembali tepat waktu (Sesuai dengan ketentuan Surat Jalan).</li>
        <hr/>
        <li>Jika langsung diarahkan oleh pihak gudang untuk retur, tanda terima retur yang menyatakan barang telah diterima oleh gudang (wajib) &amp; foto barang telah di retur/diterima (jika ada) perlu dikirimkan ke Kargo.</li>
      </ul>
    </>
  );
}

export default Page5;

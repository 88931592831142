import React from 'react';

function Page6() {
  return (
    <>
      <h1>Penundaan Pembayaran</h1>
      
      <ul>
        <li>Mitra Transporter diharapkan dapat memantau agar Mitra Pengemudi / Supir selalu menggunakan aplikasi Mitra Kargo saat mengambil DO dari Kargo Tech.</li>
        <hr/>
        <li>Hal ini dikarenakan terdapat penundaan pembayaran hingga 10 hari kerja apabila Mitra Pengemudi tidak menggunakan aplikasi. Berikut penjelasannya:</li>
      </ul>
      
      <hr/>

      
      
      <h1>Mitra Pengemudi hanya menggunakan Aplikasi saat proses muat.</h1>

      <h2>Transporter dengan pembayaran 50:50</h2>
      <ul>
          <li><strong>Pembayaran pertama (First Payment)</strong> - Dibayarkan setelah SJ muat diterima dan di approve tim Kargo via Aplikasi/</li>
          <li><strong>Pembayaran pelunasan (Second Payment)</strong> - Dibayarkan 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima di Pitstop.</li>
      </ul>
      
      <hr/>
      
      <h2>Transporter dengan pembayaran TOP</h2>
      <ul>
          <li>Pembayaran pertama dan Pembayaran Pelunasan dibayarkan sesuai dengan TOP masing-masing Mitra dengan tambahan (+) 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve tim Kargo via Aplikasi dan diterima tim Kargo di Pitstop.</li>
          <li>Simulasi pembayaran: Jika transporter mempunyai TOP 5 hari, maka total 15 hari kerja akan dibayarkan.</li>
      </ul>
    
      <hr/>



      <h1>Mitra Pengemudi hanya menggunakan Aplikasi saat bongkar</h1>

      <h2>Transporter dengan pembayaran 50:50</h2>
      <ul>
          <li>Pembayaran pertama akan dibayarkan bersamaan dengan Pembayaran pelunasan setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve tim Kargo via Aplikasi dan diterima di Pitstop.</li>
      </ul>
      <hr/>

      <h2>Transporter dengan pembayaran TOP</h2>
      <ul>
          <li>Pembayaran pertama dan pembayaran pelunasan dibayarkan sesuai dengan TOP masing-masing Mitra dengan tambahan (+) 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve oleh tim Kargo via aplikasi dan diterima di Pitstop. </li>
          <li>Simulasi pembayaran: Jika transporter mempunyai TOP 5 hari, maka total 15 hari kerja akan dibayarkan. </li>
      </ul>

      <hr/>



      <h1>Mitra Pengemudi yang tidak menggunakan Aplikasi Mitra Kargo sama sekali</h1>

      <h2>Transporter dengan pembayaran 50:50</h2>
      <ul>
          <li>Pembayaran pertama dan Pembayaran pelunasan akan dibayarkan (+) 10 hari kerja dengan catatan semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) sudah diterima Kargo baik via Aplikasi maupun di Pitstop. </li>
      </ul>

      <h2>Transporter dengan pembayaran TOP</h2>
      <ul>
          <li>Pembayaran pertama dan pembayaran pelunasan dibayarkan sesuai dengan TOP masing-masing Mitra dengan tambahan (+) 10 hari kerja setelah semua Surat Jalan dan dokumen lainnya (seperti Invoice dan Faktur Pajak, jika dibutuhkan) diterima dan di approve oleh tim Kargo via aplikasi dan diterima di Pitstop.</li>
          <li>Simulasi pembayaran: Jika transporter mempunyai TOP 5 hari, maka total 15 hari kerja akan dibayarkan. </li>
      </ul>
    </>
  );
}

export default Page6;

import React from 'react';

function Page4() {
  return (
    <>
      <h1>Pembayaran</h1>
      
      <ul>
        <li>Pembayaran On-Call dapat diatur dengan TOP, atau 100% di akhir atau 50% setelah muat dan 50% setelah bongkar (Tanpa TOP).</li>
        <hr/>
        <li>Mitra Vendor dengan NPWP akan dipotong PPh 2%. Mitra Transporter tanpa NPWP akan dipotong PPh 4%.
        <ul><li>Harap lampirkan SKB di awal, jika ada. Bila hingga proses penempatan Pengemudi selesai dan dokumen perpajakan tidak dilampirkan, maka ketentuan perpajakan akan diterapkan sesuai dengan keadaan dokumentasi yang ada, dengan tunduk pada ketentuan perpajakan yang berlaku di Indonesia.</li></ul></li>
        <hr/>
        <li>Mitra Vendor dengan status Pengusaha Kena Pajak (PKP) diwajibkan melakukan upload dokumen pengukuhan PKP.
          <ul><li>Bila hingga proses penempatan Pengemudi selesai dan dokumen tidak dilampirkan, maka ketentuan perpajakan akan diterapkan sesuai dengan keadaan dokumentasi yang ada, dengan tunduk pada ketentuan perpajakan yang berlaku di Indonesia.</li></ul>
        </li>
        <br/>
        <li>Harap konfirmasi preferensi pembayaran On-Call dan Status Usaha Anda melalui email <strong>vendormanagement@kargo.tech</strong> dengan format:</li>
        <div className="boxed">
          Nama Akun:<br/>
          Email:<br/>
          Pilihan Pembayaran On-Call: 50:50 / TOP ... hari (Pilih salah satu)<br/>
          Status Usaha: PKP dan SKB / Non-PKP / Individu dengan NPWP / Individu tanpa NPWP (Pilih salah satu)
        </div>
        <hr/>
        <li>Mitra Transporter tanpa TOP akan menerima DP 50% pertama <strong>saat Surat Jalan Muat sudah difoto dengan baik dan disetujui oleh Kargo melaluli Aplikasi (dengan catatan Mitra Pengemudi menggunakan aplikasi Mitra Kargo dengan baik dan benar).</strong> Pembayaran akan dirilis di hari itu juga jika Surat Jalan Muat diterima <strong>sebelum 15.00 WIB.</strong></li>
        <hr/>
        <li>Mitra Transporter dengan TOP akan menerima full 100% pembayaran <strong>saat Surat Jalan Muat dan Bongkar sudah difoto dengan baik dan diterima Kargo di Pitstop Kargo (dengan catatan Mitra Pemgemudi menggunakan aplikasi Mitra Kargo dengan baik dan benar).</strong> Pembayaran akan dirilis di hari itu juga jika Surat Jalan diterima <strong>sebelum jam 15:00 WIB.</strong></li>
      </ul>
      
    </>
  );
}

export default Page4;

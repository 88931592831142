import React from 'react';

function Page1() {
  return (
    <>
      <h1>Syarat &amp; Ketentuan Menjadi Vendor</h1>

      <ul>
        <li>Harus melengkapi dokumen legalitas dan truk Anda di aplikasi Kargo Vendor sebelum mengambil muatan.</li>
        <hr/>
        <li>Pastikan Mitra pengemudi Anda sudah download aplikasi Mitra Kargo saat sedang mengambil muatan Kargo.<br/>
          <a href="https://play.google.com/store/apps/details?id=tech.kargo.driver">Download aplikasi Mitra Kargo</a>
        </li>
      </ul>

      <hr/>

      <h1>Pelajari Aplikasi Kargo</h1>
      
      <ul>
        <li>Pelajari aplikasi Kargo Vendor untuk mulai bidding di Delivery Order Kargo lewat link berikut.<br/>
          <a href="https://www.youtube.com/watch?v=Tvze7VEMHXE">Lihat tutorial Kargo Vendor</a></li>
        <hr/>
        <li>Pelajari aplikasi Mitra Kargo lewat link Youtube berikut ini dan pastikan Mitra Pengemudi mengerti sepenuhnya.<br/>
          <a href="https://www.youtube.com/watch?v=wGwJp3Xtc5U">Lihat tutorial Mitra Kargo</a></li>
      </ul>
    </>
  );
}

export default Page1;

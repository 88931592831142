import React from 'react';

function Page8() {
  return (
    <>
      <h1>Prosedur Denda Keterlambatan Pengembalian Surat Jalan</h1>

      <ul>
          <li>Pengembalian POD/Surat Jalan maksimal H+3 setelah proses bongkar. Terdapat denda sebesar Rp 100,000,- per hari keterlambatan sampai POD dikembalikan ke tim Kargo (denda terhitung di hari ke-4).</li>
          <hr/>
          <li>POD yang tidak dikembalikan/tidak diterima Kargo lebih dari 7 hari akan dianggap hilang dan Mitra Transporter akan dikenakan denda dengan detil sebagai berikut:
              <ul>
                  <li>Mitra Transporter dengan pembayaran 50% - 50%, biaya pelunasan tripnya tidak akan dibayarkan.</li>
                  <li>Mitra Transporter dengan pembayaran TOP atau 100% di akhir, biaya tripnya yang berkaitan dengan POD yang hilang tersebut akan dipotong sebesar 50%.</li>
              </ul>
          </li>
          <hr/>
          <li>Mitra Transporter yang kehilangan POD hanya akan menerima 50% pembayaran pada trip yang berkaitan dengan POD tersebut.</li>
          <hr/>
          <li>Apabila dalam Catatan Transporter terdapat pesan "Transporter Tidak Perlu Mengirimkan Surat Jalan ke Kargo", maka POD akan diurus langsung antara Pengemudi/Transporter dengan Shipper. Segala bentuk biaya tambahan akan diselesaikan langsung dengan Shipper.</li>
      </ul>
    </>
  );
}

export default Page8;

import React from 'react';

function Page9() {
  return (
    <>
      <h1>Penerbitan Invoice</h1>

      <ul>
          <li>Mitra Transporter membuat invoice ditujukan kepada PT Panthera Biru Indonesia dengan kop surat perusahaan, tanda tangan, cap basah dan materai.</li>
          <hr/>
          <li>Invoice untuk DO menggunakan Transporter App Kargo (melalui bidding) harap dipisah dengan DO yang tidak melalui Transporter App Kargo (melalui offline/grup Whatsapp).</li>
          <hr/>
          <li>Harap selalu melampirkan Faktur Pajak di setiap invoice yang dikirim.</li>
          <hr/>
          <li>Dianjurkan untuk memisahkan Invoice pembayaran ritase/trip dengan pembayaran biaya muat, bongkar (TKBM) dan biaya menginap.</li>
          <hr/>
          <li>Kargo dapat menolak melakukan pembayaran atas Invoice yang menurut pendapat Kargo belum lengkap sampai dianggap lengkap.</li>
      </ul>
    </>
  );
}

export default Page9;

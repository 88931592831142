import React from 'react';
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginLeft: '0px',
    marginRight: '0px',
    background: '#dfe4e8',
    paddingRight: '16px',
    borderRadius: '4px',
    marginBottom: '32px'
  },
  label: { fontSize: '14px' }
});

function Page11({ isChecked, onCheckedChange }) {
  const classes = useStyles();

  return (
    <>
      <h1>Pusat Bantuan Pembayaran</h1>

      <ul>
          <li>Segala bentuk pertanyaan, pengaduan, laporan dan informasi pembayaran akan diinformasikan atau ditanggapi melalui email agar memudahkan pihak transporter dan pihak Kargo dalam melakukan pelacakan dan memberikan tanggapan secara cepat.</li>
          <hr/>
          <li>Email yang dapat digunakan transporter untuk mengajukan pertanyaan, pengaduan dan laporan pembayaran : <strong>paymentteam@kargo.tech</strong></li>
          <hr/>
          <li>Terdapat kriteria yang harus dipenuhi transporter dalam melakukan laporan atau pengaduan, yaitu:
              <ol>
                  <li>Subject yang jelas.</li>
                  <li>Pada badan email harus menyertakan nomor DO atau setidaknya tanggal shipment, nomor plat mobil, asal dan tujuan shipment. Ini bertujuan untuk memudahkan kami dalam melakukan pengecekan.</li>
              </ol>
          </li>
      </ul>

      <hr/>
      
      <h1>Lain-lain</h1>
      <ul><li>Kargo dapat merubah atau menyesuaikan isi syarat dan ketentuan ini dari waktu ke waktu jika dianggap perlu. Semua penyesuaian atau perubahan berlaku sejak ditampilkan.</li></ul>

      <div style={{ textAlign: 'center' }}>
        <FormControlLabel
          classes={{
            root: classes.root,
            label: classes.label
          }}
          control={<Checkbox color="primary" checked={isChecked} onChange={onCheckedChange} />}
          label="Saya menyetujui Syarat dan Ketentuan"
        />
      </div>
    </>
  );
}

export default Page11;
